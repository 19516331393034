// Keyframe animations
@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  30% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  30% {
    opacity: 0;
    transform: translateX(10rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-10rem);
  }

  30% {
    opacity: 0;
    transform: translateY(-10rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }

  30% {
    opacity: 0;
    transform: translateY(10rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

// -------------------------------------------------------------------------------
//Mixins
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin gradient {
  background-image: linear-gradient(
    to right bottom,
    $color-grey-dark-1,
    $color-black
  );
}

@mixin fade-timing {
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@mixin fadeRight {
  animation-name: fadeRight;
  @include fade-timing;
}

@mixin fadeLeft {
  animation-name: fadeLeft;
  @include fade-timing;
}

@mixin fadeDown {
  animation-name: fadeDown;
  @include fade-timing;
}

@mixin fadeUp {
  animation-name: fadeUp;
  @include fade-timing;
}

@mixin respond($breakpoint) {
  @if $breakpoint == 420 {
    @media only screen and (max-width: 420px) {
      @content;
    }
  }
  @if $breakpoint == 500 {
    @media only screen and (max-width: 500px) {
      @content;
    }
  }
  @if $breakpoint == 650 {
    @media only screen and (max-width: 650px) {
      @content;
    }
  }
  @if $breakpoint == 600 {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == 700 {
    @media only screen and (max-width: 700px) {
      @content;
    }
  }
  @if $breakpoint == 750 {
    @media only screen and (max-width: 750px) {
      @content;
    }
  }
  @if $breakpoint == 800 {
    @media only screen and (max-width: 800px) {
      @content;
    }
  }
  @if $breakpoint == 850 {
    @media only screen and (max-width: 850px) {
      @content;
    }
  }
  @if $breakpoint == 878 {
    @media only screen and (max-width: 878px) {
      @content;
    }
  }
  @if $breakpoint == 900 {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == 953 {
    @media only screen and (max-width: 953px) {
      @content;
    }
  }
  @if $breakpoint == 1000 {
    @media only screen and (max-width: 1000px) {
      @content;
    }
  }
  @if $breakpoint == 1068 {
    @media only screen and (max-width: 1068px) {
      @content;
    }
  }
  @if $breakpoint == 1074 {
    @media only screen and (max-width: 1074px) {
      @content;
    }
  }
  @if $breakpoint == 1100 {
    @media only screen and (max-width: 1100px) {
      @content;
    }
  }
  @if $breakpoint == 1137 {
    @media only screen and (max-width: 1137px) {
      @content;
    }
  }
  @if $breakpoint == 1160 {
    @media only screen and (max-width: 1160px) {
      @content;
    }
  }
  @if $breakpoint == 1200 {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }
  @if $breakpoint == 1251 {
    @media only screen and (max-width: 1251px) {
      @content;
    }
  }
  @if $breakpoint == 1300 {
    @media only screen and (max-width: 1300px) {
      @content;
    }
  }
  @if $breakpoint == 1350 {
    @media only screen and (max-width: 1350px) {
      @content;
    }
  }
  @if $breakpoint == 1400 {
    @media only screen and (max-width: 1400px) {
      @content;
    }
  }
  @if $breakpoint == 1400min {
    @media only screen and (min-width: 1400px) {
      @content;
    }
  }
  @if $breakpoint == 1800min {
    @media only screen and (min-width: 1800px) {
      @content;
    }
  }
  @if $breakpoint == 2000min {
    @media only screen and (min-width: 2000px) {
      @content;
    }
  }
  // @if $breakpoint == 600maxh {
  //   @media only screen and (max-height: 599px) {
  //     @content;
  //   }
  // }
}

// ---------------------------------- VARIABLES -----------------------------------
// COLORS
$color-red: #9f0712;

$color-grey-dark-1: #999;
$color-grey-dark-2: #777;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

// FONT
$default-font-size: 1.5rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 2.5rem;

// -------------------------------------------------------------------------------
// CSS declarations for screens between 1200px width and 2000 px width
a,
a:hover {
  text-decoration: none;
  color: $color-white;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: $color-red;
  font-family: source code pro;
  min-width: 320px !important;
}

// body {
//   background-color: $color-red;
//   font-family: source code pro;
//   min-width: 320px !important;
// }

// Buttons -----------------------------------------------------------------------
.btn {
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.8rem 2rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all 0.2s;
    border: none;
    cursor: pointer;
  }

  &:hover {
    transform: translateY(-0.3rem);
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  }
}

.buttonStyle {
  color: white;
  background: $color-red;
  border-radius: 10rem;
  font-size: 1rem;

  @include respond(900) {
    font-size: 0.8rem;
  }
}

// Top header ----------------------------------------------------------------------
.headerTop {
  padding: 3% 10%;

  &__container {
    position: relative;
    top: 2vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    @include respond(1800min) {
      padding: 4rem 1rem;
    }
  }

  &__logo {

    @include fadeDown;

    @include respond(1200) {
      top: 2%;
    }

    @include respond(1800min) {
      top: -7vw;
    }
  }

  &__logo h1 a {
    font-family: "Homemade Apple";
    font-size: 3rem;
    color: white;

    @include respond(1200) {
      font-size: 2.5rem;
      position: relative;
      top: 1rem;
    }

    @include respond(600) {
      font-size: 2rem;
      left: -6%;
    }

    @include respond(500) {
      font-size: 1.6rem;
    }

    @include respond(1800min) {
      font-size: 4rem;
    }
  }

  &__nav-container {
    cursor: pointer;
    z-index: 10;
    position: relative;
    top: 1vw;

    @include fadeDown;

    @include respond(1200) {
      display: none;
    }

    &--nav-item {
      font-family: source code pro;
      padding-bottom: 3rem;

      @include respond(1800min) {
        padding-bottom: 4rem;
      }
    }

    &--nav-link {
      color: white;
      font-size: 1.5vw;
      float: right;

      // @include respond(1800min) {
      //   font-size: 2rem;
      // }
    }

    &--nav-link:hover {
      color: $color-grey-dark-1;
    }

    &--nav-link-open {
      cursor: pointer;
      color: white;
      font-size: 1.5vw;
      float: right;
      padding-top: 0.6rem;
    }

    &--nav-link-open:hover {
      color: $color-grey-dark-1;
    }

    &--dropdownStyling {
      background-color: transparent;
      margin: 0.25rem;
      padding-left: 1rem;
    }

    &--dropdown-item {
      color: white !important;
      font-size: 1.5vw;
      padding-left: 0.5rem;

      // @include respond(420) {
      //   font-size: 0.7rem;
      // }

      // @include respond(2000min) {
      //   font-size: 1.5rem;
      // }
    }

    &--dropdownItemSS {
      color: white !important;
      font-size: 1.5vw;
      // text-align: left;

      @include respond(900) {
        font-size: 2.5vw;
      }
    }

    &--dropdown-item:hover {
      color: grey !important;
      background-color: transparent !important;
    }

    &--dropdownItemSS:hover {
      color: gray !important;
      background-color: transparent !important;
    }

    &--ddItemPadding {
      padding-top: 0.5rem;
    }
  }
}

// Hambuger Nav --------------------------------------------------------------------
.hamburgerNav {
  &__sidebar {
    height: 100vh;
    width: 25%;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 75%;
    background-color: $color-red;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;

    @include fadeLeft;

    @include respond(800) {
      width: 30%;
      left: 70%;
    }

    @include respond(500) {
      width: 40%;
      left: 60%;
    }

    @include respond(420) {
      width: 45%;
      left: 55%;
    }
  }

  &__closeButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #dce5d8;
    font-size: 2.5rem;
    position: absolute;
    top: 2%;
    right: 10%;
    z-index: 10;
  }

  &__navbar-ss {
    position: relative;
    top: -5%;

    @include respond(900) {
      margin-top: 0;
    }
  }

  &__nav-item-ss {
    padding-top: 1rem;
  }

  &__nav-link-ss {
    font-size: 1.5vw;
    color: white;
    cursor: pointer;

    @include respond(900) {
      font-size: 2.5vw;
    }

    // @include respond(420) {
    //   font-size: 0.9rem;
    // }

    // @include respond(600maxh) {
    //   font-size: 1.1rem;
    // }
  }

  &__nav-link-ss:hover {
    color: gray;
  }

  &__contactDropdownOpen {
    color: white;
    // position: relative;
    // top: 4.5vw;
    // left: 0;
    font-size: 1.5vw;

    // @include respond(1100) {
    //   left: -14.7vw;
    // }

    @include respond(1000) {
      top: 1vw;
      left: 0vw;
      // font-size: 2vw;
    }

    @include respond(900) {
      font-size: 2.5vw;
    }
  }

  &__contactDropdownOpen:hover {
    color: gray;
  }

  &__dropdown-toggle::after {
    display: none;
  }

  &__icons-ss {
    position: relative;
    top: 10%;
  }

  &__faIcons-ss:hover {
    transform: scale(1.2);
    transition: all ease-in-out 0.2s;
  }

  &__faIcons-ss:not(:last-child) {
    padding-right: 10%;
  }
}

// Landing page ------------------------------------------------------------------
.landingPage {
  &__landingBg {
    background-image: url(images/vrlanding-med.jpg);
    background-attachment: fixed;
    height: 100vh;
    width: 100vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position-y: center;

    @include respond(1200) {
      background-position: center;
    }

    @include respond(900) {
      background-image: url(images/vrlanding-med-mobile.jpg);
      background-position-y: 0;
    }
    @include respond(600) {
      background-position: center;
    }
  }

  &__landingText {
    font-family: source code pro;
    color: white;
    font-size: 1.6vw;
    position: relative;
    top: -12vw;
    left: 0rem;
    line-height: 3.5vw;
    padding: 2% 10%;
    text-shadow: 1px 1px 1px black;

    @include fadeRight;

    @include respond(1200) {
      // font-size: 1.4rem;
      top: 2rem;
    }

    @include respond(900) {
      font-size: 2.5vw;
      line-height: 6.5vw;
    }
    // @include respond(750) {
    //   font-size: 1.2rem;
    //   top: 10rem;
    // }
    // @include respond(600) {
    //   // font-size: 1.5rem;
    //   line-height: 2rem;
    // }
    // @include respond(420) {
    //   font-size: 1.1rem;
    // }
    @include respond(1800min) {
      font-size: 1.7vw;
      line-height: 3.5vw;
      top: -12vw;
    }
  }

  &__bookButton {

    @include fadeUp;

    @include respond(1800min) {
      font-size: 1.5rem;
    }
  }
}

// About section -----------------------------------------------------------------
.about {
  &__section {
    background: white;
    margin: 1rem 0rem;
  }

  &__landing {
    padding: 5%;
    display: flex;

    @include respond(1000) {
      padding: 3%;
    }

    @include respond(900) {
      flex-direction: column;
    }
  }

  &__imgDiv {
    width: 50%;

    @include fadeRight;

    @include respond(900) {
      width: 100%;
    }
  }

  &__textDiv {
    width: 50%;
    padding-left: 5%;
    position: relative;
    top: -0.5rem;
    align-self: center;

    @include fadeLeft;

    @include respond(1137) {
      top: 0;
    }

    @include respond(900) {
      width: 100%;
      padding: 3% 0 0 0;
    }
  }

  &__headers {
    font-weight: 600;
    padding: 0.5% 0%;
    font-size: 1.5vw;

    // @include respond(1300) {
    //   font-size: 1rem;
    // }

    // @include respond(1068) {
    //   font-size: 0.8rem;
    // }

    // @include respond(953) {
    //   font-size: 0.75rem;
    // }

    @include respond(900) {
      font-size: 2.5vw;
    }

    // @include respond(600) {
    //   font-size: 1rem;
    // }

    // @include respond(1800min) {
    //   font-size: 1.5vw;
    // }
  }

  &__text {
    font-size: 1vw;

    // @include respond(1300) {
    //   font-size: 0.8rem;
    // }

    // @include respond(1251) {
    //   // font-size: 0.75rem;
    //   line-height: 1.2;
    // }

    // @include respond(1137) {
    //   font-size: 0.75rem;
    // }

    // @include respond(1068) {
    //   font-size: 0.7rem;
    // }

    // @include respond(953) {
    //   font-size: 0.65rem;
    // }

    @include respond(900) {
      font-size: 2vw;
    }

    // @include respond(600) {
    //   font-size: 0.8rem;
    // }

    // @include respond(1800min) {
    //   font-size: 1vw;
    // }
  }
}

// Talks section ----------------------------------------------------------------
.talks {
  background: black;
  padding-top: 2rem;
  padding-bottom: 5rem;
  margin-bottom: 1rem;

  &__buttonsFlex {
    justify-content: space-between;
    margin: 0 10%;
  }

  &__buttons {
    // font-size: 1.05em !important;
    // padding: .65vw 1vw 1vw 1vw !important;

    @include respond(1800min) {
      // padding: .25vw 1vw 1vw 1vw !important;
      // font-size: 1.25vw !important;
    }

    @include respond(1100) {
      padding: .5vw 1vw 1vw 1vw;
    }
  }


  &__book {
    @include fadeRight;
  }

  &__heading {
    color: white;
    width: 70%;

    @include fadeDown;

    @include respond(700) {
      margin: 0;
    }

    @include respond(600) {
      width: 50%;
      font-size: 1.1rem;
    }
  }

  &__browse {
    @include fadeLeft;
  }
}

// Talk cards ---------------------------------------------------------------------
.talkCards {
  background-color: $color-red;
  padding: 3rem;
  margin-top: 2rem;

  .card {
    // FUNCTIONALITY
    position: relative;
    height: 42vw;
    border: 3px solid transparent;
    cursor: pointer;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    font-size: 1.2vw;
    overflow: hidden;

    @include respond(1200) {
      height: 46vw;
    }

    @include respond(900) {
      height: 75vw;
    }

    @include respond(800) {
      height: 65vw;
    }

    @include respond(700) {
      height: 80vw;
    }

    @include respond(600) {
      // margin-top: 2rem;
      height: 60rem;
    }

    @include respond(500) {
      height: 42rem;
    }

    @include respond(400) {
      height: 40rem;
    }

    &:hover {
      transition: 0.3s;
      transform: scale(1.05) translateY(-0.5rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, 0.5);
      z-index: 20;

      @include respond(600) {
        transition: none;
        transform: none;
        box-shadow: none;
      }
    }

    &:not(:last-child) {
      margin-left: 2rem !important;
    }

    &__side {
      height: 42vw;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 3px;
      overflow: hidden;
      box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);

      &--front {
        background-color: $color-white;
      }

      @include respond(1200) {
        height: 46vw;
      }

      @include respond(900) {
        height: 75vw;
        overflow: visible;
      }

      @include respond(800) {
        height: 65vw;
      }

      @include respond(700) {
        height: 80vw;
      }
    }

    // FRONT SIDE STYLING
    &__picture {
      background-size: cover;
      height: 30vw;
      background-blend-mode: screen;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      &--1 {
        background-image: url(images/VRegas_HS1_cropped.jpg);
      }

      &--2 {
        background-image: url(images/Val_speaking2_cropped.jpg);
      }

      &--3 {
        background-image: url(images/VRegas1_cropped.jpg);
      }

      &--4 {
        background-image: url(images/VRegas_HS2_cropped.jpg);
      }

      // @include respond(1200) {
      //   height: 17rem;
      // }

      // @include respond(1074) {
      //   height: 15rem;
      // }

      @include respond(900) {
        height: 29rem;
      }

      @include respond(800) {
        height: 20rem;
      }

      @include respond(600) {
        height: 47rem;
        background-position: center;
      }

      @include respond(500) {
        height: 30rem;
      }

      @include respond(400) {
        height: 27rem;
      }
    }

    &__details {
      padding: 1rem 0.5rem;
      color: $color-black;
      background-color: white !important;

      &:hover {
        color: $color-grey-dark-2;
      }

      @include respond(900) {
        font-size: 2vw;
      }

      @include respond(800) {
        padding: 1rem !important;
      }

      @include respond(600) {
        font-size: 3vw;
      }

      @include respond(500) {
        font-size: 3.5vw;
      }
    }
  }
}

// @media only screen and (max-width: 600px) {
//   div.card:not(:last-child):after {
//     // content: '';
//     margin-bottom: 2rem;
//   }
// }

//Footer landing photo ----------------------------------------------------------
.footerBg {
  // background-image: url(images/vrlanding_footer.png);
  // background-attachment: fixed;
  // min-height: 100vh;
  // max-width: 100vw;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
  // background-position: center center;
  // background-repeat: no-repeat;
  // margin: auto;

  // @include respond(1350) {
  //   height: 43vw;
  // }

  // @include respond(1000) {
  //   height: 60vw;
  // }

  // @include respond(900) {
  //   display: none;
  // }

  // #footerBook {
  //   position: relative;
  //   top: -12rem;
  //   left: 11%;

  //   @include respond(1200) {
  //     top: 1rem;
  //   }
  // @include respond(900) {
  //   top: 10rem;
  //   left: 10rem;
  // }

  // @include respond(800) {
  //   top: 10rem;
  //   left: 8rem;
  // }

  // @include respond(700) {
  //   top: 6rem;
  //   left: 6rem;
  // }

  // @include respond(600) {
  //   top: 3rem;
  //   left: 5rem;
  // }

  // @include respond(500) {
  //   left: 3.5rem;
  // }
  // }
}

// Contact ----------------------------------------------------------------------
.contact {
  .contactBg {
    background-image: url(./images/valContact.jpg);
    background-attachment: initial;
    background-attachment: fixed;
    min-height: 100vh;
    width: 100vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-position-y: center;

    @include respond(1200) {
      background-position: center;
    }

    @include respond(900) {
      background-image: url(images/valContact-900.jpg);
      height: 70vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    // @include respond(600) {
    //   background-image: url(images/valContact-ss.jpg);
    //   // min-height: 100vh;
    //   background-size: cover;
    //   background-repeat: no-repeat;
    // }
  }

  // &__contactDiv {
  //   position: relative;
  //   top: 1vw;
  // }
  .contactMe {
    font-size: 3rem;
    font-family: "Homemade Apple";
    position: relative;
    left: 55vw;
    color: white;
    top: 8vw;

    @include respond(1100) {
      font-size: 2.5rem;
      left: 61vw;
    }

    @include respond(900) {
      left: 7vw;
    }
  }

  .contact-form {
    width: 35%;
    float: right;
    position: relative;
    top: 15vw;
    color: white;
    font-size: 1.2rem;

    @include fadeLeft;

    @include respond(900) {
      width: 50%;
      float: none;
      top: 35vw;
      margin: auto;
    }

    // @include respond(1200) {
    //   top: 6rem;
    // }

    // @include respond(900) {
    //   font-size: 1.5rem;
    //   top: 38rem;
    //   float: left;
    //   padding-left: 11.5%;
    //   padding-bottom: 5rem;
    // }

    // @include respond(800) {
    //   top: -15rem;
    // }

    // @include respond(750) {
    //   top: -12rem;
    // }

    // @include respond(750) {
    //   top: -9rem;
    // }

    // @include respond(650) {
    //   top: -6rem;
    // }

    // @include respond(600) {
    //   top: -3rem;
    // }
  }

  .contact-input {
    width: 22vw;
    border: none;
    border-bottom: 2px solid white;
    background: transparent;

    @include respond(900) {
      width: 100%;
    }
  }

  .contact-input:focus {
    outline: double white;
    border-bottom: none;
  }

  .contact-title {
    text-align: center;
    color: white;
    transition: all 4% ease-in-out;
  }

  #contactButton {
    margin-top: 1vw;
    background: transparent;
    border: none;
    color: white;
    text-shadow: 1px 1px 1px black;

    // @include respond(900) {
    //   background-color: white;
    //   color: $color-red;
    //   margin-top: 5vw;
    // }
  }

  #contactForm {
    background: transparent;
  }

  #contactInfo {
    position: relative;
    top: 6vw;
    color: white;
    width: max-content;
  }

  #contactLogo {
    position: relative;
  }
}

#sendButtonContact {
  padding: 1vw .5vw 2vw .5vw;
  line-height: 3.5;

  &::before,
  &::after {
    box-shadow: 1px 1px 1px black;
  } 

  // @include respond(1000) {
  //   padding: 0.5vw 1vw 1.5vw 1vw;
  // }

  // @include respond(500) {
  //   padding: 0.5vw 1vw 2vw 1vw;
  // }
}

// Footer -------------------------------------------------------------------------
.footer {
  background-color: #9f0712;
  color: white;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1% 3% 4.5% 10%;
  height: 3.4vw;
  z-index: 100;

  // @include respond(1200) {
  //   bottom: -22rem;
  // }

  // @include respond(900) {
  //   bottom: -24rem;
  // }

  @include respond(600) {
    padding-bottom: 15%;
    padding-top: 5%;
  }

  @include respond(1800min) {
    padding-top: .5vw;
  }

  &__flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    @include fadeUp;

    @include respond(600) {
      padding: 0.5rem 1rem .5rem 0rem;
    }
  }

  .copyrightText {
    width: 80%;
    float: left;
    vertical-align: middle;
  }

  .copyrightTextFontSize {
    font-size: 1.3vw;

    @include respond(600) {
      font-size: 2.5vw;
    }

    // @include respond(900) {
    //   font-size: 1rem;
    // }

    // @include respond(500) {
    //   font-size: 0.7rem;
    // }

    // @include respond(2000min) {
    //   font-size: 1.6rem;
    // }
  }

  .icons {
    width: 20%;
    padding-right: 2%;

    // @include respond(1200) {
    //   display: none;
    // }

    @include respond(900) {
      padding-right: 2%;
    }

    @include respond(500) {
      padding: 0;
    }
  }

  .iconFloat {
    float: right;
  }

  .upArrow {
    margin-left: 5vw;
    // position: relative;
    // top: -3vw;
    // left: 81vw;
    // cursor: pointer;
  }
}
//-------------------------------------------------------------------------------
//-------------------------------------------------------------------------------
//-------------------------------------------------------------------------------

// footer {
//   height: 6vw;
// }

.blankSpace {
  height: 10vw;
  background-color: transparent;
}

form {
  transition: all 4% ease-in-out;
}

form .submit {
  background: lightgrey;
  border-color: transparent;
  color: white;
}

form .submit:hover {
  background: darkgrey;
  cursor: pointer;
}

html {
  background-color: $color-red;
}

h2,
.h2 {
  font-size: 1.5em;
}

ul {
  list-style-type: none;
}

ul.row {
  padding-left: 0% !important;
}

.blackBackground {
  background-color: black !important;
}

.bookMeFade {
  @include fadeUp;
}

.buttonStyle:hover {
  color: slategray;
}

.carousel {
  width: 56% !important;
  margin: auto;

  @include respond(900) {
    width: 80% !important;
  }
}

.carousel * {
  @include respond(600) {
    display: block;
  }
}

.carousel.carousel-slider {
  margin: auto !important;
}

.carousel.carousel-slider .control-arrow {
  top: -15vw !important;
  font-size: 26px;
  bottom: 0;
  margin-left: 15vw;
  position: fixed !important;
  padding: -14px !important;
  z-index: 99;
  opacity: 1 !important;
  color: #fff;
  font-size: 26px;
  margin-top: 18vw !important;
  padding: 5px;
  height: 50%;
}

.carousel .carousel-status {
  position: absolute;
  top: -0.2vw !important;
  right: 3vw !important;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;

  @include respond(1200) {
    display: none !important;
  }
}

.carousel .control-dots {
  bottom: 1vw;
  left: 0.75vw;
  margin: auto;

  @include respond(900) {
    display: none;
  }
}

.carousel .control-next.control-arrow {
  right: 15vw !important;
}

.carousel .slide img {
  width: 50% !important;

  @include respond(600) {
    width: 100% !important;
    height: auto;
  }
}

.carousel .slide .legend {
  bottom: 26vw !important;
  left: 65.4vw !important;
  position: absolute;
  margin-left: -13% !important;
  width: 20% !important;
  background: #272727 !important;
  color: #fff;
  padding: 10px;
  font-size: 1vw;
  text-align: center;
  opacity: 0.25;
  transition: opacity 0.35s ease-in-out;
  border-radius: 0px !important;
}

.carousel .slide .legend:hover {
  background-color: #272727;
}

.carousel .slider-wrapper {
  height: 33vw;

  @include respond(600) {
    height: 100vw;
    display: inline-block;
  }
}

.carousel .thumbs {
  padding: 0;
}

.carousel .thumbs-wrapper {
  margin-top: 2vw !important;
  overflow: hidden;
}

.carousel-root {
  margin-top: 3vw;
}

.clear {
  clear: both;
}

.container {
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.dropdown-menu {
  position: relative;
  top: 1rem;
  left: 3rem;
  background-color: transparent;
  z-index: 1000 !important;
  /* color: rgba(0, 0, 0, 0.9); */
  margin: 0;
  /* padding: 0rem 1rem; */
  text-align: right;
  list-style: none;
  border: none !important;
  display: inline-block !important;
}

.dropdown-menu-ss {
  position: relative;
  top: 0;
  left: -1rem;
  z-index: 1000 !important;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
  padding: 0rem 1rem;
  text-align: left;
  list-style: none;
  border: none !important;
  display: inline-block !important;
}

// .dropdown-menu-ss {
//   text-align: left;
// }

.effect-text-online-mkls {
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: white;
}

.fab {
  color: white;
  font-size: 1.5vw;
}

.fa-bars {
  color: white;
  position: relative;
  top: 1rem;
  right: -1rem;
  cursor: pointer;
  @include fadeDown;
}

.faIcons {
  padding-left: 2rem;
  vertical-align: center;

  @include respond(500) {
    padding-left: 0.6rem;
  }
}

.flex {
  display: flex;
}

.floatRight {
  float: right;
}

.footerMargin {
  padding-top: 1rem;
}

.form-control {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  border-bottom: 1px solid white !important;
  color: white !important;
  margin: 0% 25%;
  width: 50% !important;
}

.fullWidth {
  width: 100vw;
}

.gallery {
  margin-top: 5%;
}

// .header {
//   background: transparent;
// }

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, red, blue);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;

  @include respond(900) {
    font-size: 3rem;
  }

  @include respond(600) {
    font-size: 2.5rem;
  }

  &:hover {
    transform: skewY(2deg) skewX(15deg) scale(1.1);
    text-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
  }
}

.iframe {
  z-index: 99;
}

.imgFill {
  width: 100%;
  height: 100%;
}

// .landingCenterText {
//   font-family: source code pro;
//   color: white;
//   font-size: 1.5vw;
//   position: absolute;
//   top: 25%;
//   left: 1%;
//   line-height: 250%;
//   padding: 2% 10% 0% 10%;

//   @include fadeRight;

//   @include respond(900) {
//     top: 23%;
//     font-size: 1.1rem;
//   }

//   @include respond(600) {
//     line-height: 1.5;
//     top: 57vh;
//   }

//   @include respond(1800min) {
//     top: 20%;
//   }
// }

// .mediaDiv {
//   cursor: pointer;
// }

.portfolioBodyText {
  font-size: 1vw;
}

.portfolioCard {
  background: white;
  width: 100%;
  height: 100%;
  padding: 3%;
  border-left: solid 1.5vw #272727 !important;
  color: black;

  @include respond(600) {
    position: absolute;
    top: 65vw;
    left: 14%;
    width: 72%;
    border-left: none !important;
    text-align: center;
    overflow: auto;
  }
}

.portfolioCard:hover {
  color: gray;
}

.portfolioPic {
  width: 56vw;
  height: 28vw;
  border: solid 1.5vw #272727;

  @include respond(900) {
    height: 30vw;
    width: 60vw;
  }
}

.portfolioTitleText {
  color: $color-red;
  font-size: 2vw;
  font-weight: bolder;
  font-family: Homemade Apple;
}

.portfolioTypeText {
  font-weight: bolder;
  font-size: 1.4vw;
}

.row {
  max-width: $grid-width;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: $gutter-vertical;
  }

  @include respond(900) {
    max-width: 50rem;
    padding: 0 3rem;
  }

  @include respond(600) {
    padding: 0;
  }

  @include clearfix;

  [class^="col-"] {
    float: left;

    &:not(:last-child) {
      margin-right: $gutter-horizontal;

      @include respond(900) {
        margin-right: 0;
        margin-bottom: 0;
      }

      @include respond(600) {
        margin-bottom: 2rem;
      }
    }

    @include respond(900) {
      width: 50% !important;
      padding: 2%;
    }

    @include respond(600) {
      width: 100% !important;
      padding: 0;
    }
  }

  .col-1-of-2 {
    width: calc((100% - #{$gutter-horizontal}) / 2);
  }

  .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
  }

  .col-2-of-3 {
    width: calc(
      2 * ((100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal}
    );
  }

  .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
  }

  .col-2-of-4 {
    width: calc(
      2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal}
    );
  }

  .col-3-of-4 {
    width: calc(
      3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + 2 * #{$gutter-horizontal}
    );
  }
}

// div.row {
//   @include respond(600) {
//     width: 100% !important;
//     padding: 0 !important;
//   }
// }

.row2 {
  margin: 5vw 0vw 0vw 0vw;

  @include respond(750) {
    margin-top: 15vw;
    margin-bottom: 50%;
  }

  @include respond(600) {
    margin: 0;
    margin-top: 2vw;
  }
}

.socialImg {
  width: 1.6rem;
  height: auto;

  @include respond(900) {
    width: 1.3rem;
  }

  // @include respond(500) {
  //   width: 1rem;
  // }
}

.talkLinks {
  width: 50%;
  height: 22vw;
  padding: 0% 5% 5% 5%;
  @include fadeUp;

  @include respond(600) {
    width: 100%;
    height: fit-content;
  }
}

.techText {
  color: $color-red;

  @include respond(750) {
    font-size: 0.7rem;
  }

  @include respond(600) {
    font-size: 0.7rem;
  }
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;

  @include respond(900) {
    margin-bottom: 5rem !important;
  }
}

.upArrowAbout {
  position: relative;
  top: -3vw;
  left: 5vw;
}

.weRiseImg {
  width: 50%;
}

.whiteText {
  color: white !important;
  margin: 1vw 0vw;

  @include respond(750) {
    padding-bottom: 10%;
    font-size: 0.85rem;
  }
}

#hamburger-nav {
  display: none;

  @include respond(1200) {
    display: block;
  }
}

#momAdvantage {
  @include respond(600) {
    height: 26vh;
  }
}

#portfolio {
  background-color: black;
  height: 60vw;

  @include respond(1400) {
    #portfolio {
      height: 90vh;
    }
  }

  @include respond(600) {
    height: 100vh;
  }

  @include respond(600maxh) {
    height: 150vh;
  }
}

#portfolioLinks {
  @include fadeUp;

  @include respond(1200) {
    position: relative;
    top: 9vw;
  }

  @include respond(800) {
    top: 16vw;
  }

  @include respond(600) {
    top: 5%;
  }
}

#portfolioLinks a:hover {
  // text-decoration: none;
}

#properCare {
  @include respond(600) {
    height: 26vh;
  }
}

// Buttons
//Lato font
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

//color/ui color variables
// $white: #ececec;
// $black: #333333;
// $yellow: #ffff00;
// $purple: #8a19ff;
// $colorPrimary: $yellow;
// $colorSecondary: $purple;

//split button mixin
// @mixin btn__split($colorLeft, $colorRight, $textColor) {
	.btn2 {
    position: relative;
    padding: 0 1vw 1vw 1vw;
    color: #fff;
    z-index: 1;
    font-size: .9rem;
    text-shadow: 1px 1px 1px black;
    line-height: 3.5vw;

		&::after,
		&::before {
			content: "";
			position: absolute;
			height: 80%;
			width: 100%;
			transition: all .5s cubic-bezier(0.2, 0, 0.265, 1.55);
      box-shadow: 1px 1px 1px black;
		}
		&::before {
			background-color: $color-red;
			top: 0rem;
			left: 0rem;
      z-index: -1;
		}
		&::after {
			background-color: #555;
			top: .5rem;
			left: .5rem;
      z-index: -2;
		}
		&:hover {
			&::before,
			&::after {
				top: 0;
				transform: skewx(0deg);
			}
			&::after {
				left: 0rem;
			}
			&::before {
				left: -39%;
			}
		}

    // @include respond(1800min) {
    //   font-size: 1.25vw;
    // }
	}
// }

// body,html{
// 	height: 100%;
// }

// body {
// 	font-family: "Lato", sans-serif;
// 	color: $black;
// 	display: flex;
// 	align-items: center;
// 	align-content: center;
// 	justify-content: center;
// }

.containerDiv {
	width: auto;
	margin: auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  @include respond(500) {
    margin-top: 7rem;
  }
}

// a {
// 	text-transform: uppercase;
// 	text-decoration: none;
// 	font-weight: 700;
// }

// @include btn__split($colorPrimary, $colorSecondary, $black);

